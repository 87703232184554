<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">

    </PageMenu>

    <div class="page-with-menu">

      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
          <template #empty>
            no records
          </template>
          <template #cell(options)="data">
            <div class="text-right">
              <b-link :to='{name: "translations.parameters", params: {locale: data.item.locale, collectionId: data.item.id}}'><BIconGlobe/></b-link>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
import {BIconGlobe} from 'bootstrap-vue';
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      PaginationRouter: () => import('@/common/components/Pagination.Router'),
      BIconGlobe
    },
    data(){
      return {
        page: {
            title: "Collections",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Translations",
                url: this.$router.resolve({name: "translations"}).href
            },
            {
                title: "Collections"
            }
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'name',
              label: "Name"
            },
            {
              key: 'placeholder',
              label: "Placeholder"
            },
            {
              key: 'options',
              label: ""
            },
          ]
        }
      }
    },
    methods: {
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'translations',query: query}).href;
      },

      getResults({pageNo},filters){
        this.table.isLoading = true;
        this.$api.get('translations/languages',{
          params: Object.assign({
            perPage: 50,
            page: pageNo,
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
          this.page.title = `Collections (${data.total})`;
        });
      },
    },
    mounted(){
      this.getResults({pageNo: 1});

      this.$emit('set-sidebar',{active: "translations"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
